/* eslint-disable prettier/prettier */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@fundo/environments/environment';
import { Observable } from 'rxjs';
import { PlaidAssetsPrepareRequest } from '../models/plaid-assets-report';

@Injectable({
  providedIn: 'root',
})
export class PlaidAssetsService {
  private readonly API_BASE = environment.apiBaseUrl;

  constructor(private httpClient: HttpClient) {}

  postUnderwritingPrepare(request: PlaidAssetsPrepareRequest): Observable<boolean> {
    return this.httpClient.post<boolean>(
      `${this.API_BASE}/underwriting/prepare`,
      request,
    );
  }
}
